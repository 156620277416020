import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import createHttpClient from '../utils/createHttpClient';
import authService from '../services/authService';
import { UserContext } from '../context/UserContext';
import alertNotification from '../utils/alertNotification';

export default function useAuth() {
  // create a new user service instance
  const { request } = useRef(createHttpClient()).current;
  const service = new authService(request);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  // set user in context and push them home
  const setUserContext = () => {
    service
      .checkUserAuth()
      .then((res) => {
        setUser(res.data.data);
        if (res.data.data.role === 'sales') {
          navigate('/create-inquiry');
        } else {
          navigate('/all-inquiries');
        }
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
        navigate('/');
      });
  };

  const loginUser = (values) => {
    service
      .loginUser(values)
      .then((res) => {
        setUserContext();

        // localStorage.setItem("r-token", res.data.data.token);
        //alertNotification("success", "Logged in successfully");
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      });
  };

  const logoutUser = () => {
    service
      .logoutUser()
      .then((res) => {
        setUser(null);
        // localStorage.removeItem("r-token");
        //alertNotification("success", "Logged out successfully");
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      });
  };
  return {
    loginUser,
    logoutUser,
  };
}
