import React,{useEffect, useState,useRef} from 'react'
import UserService from "../../services/userService";
import { Button, Form, Input, Select } from "antd";
import { useParams } from 'react-router-dom';
import createHttpClient from "../../utils/createHttpClient";
import styles from "./EditUser.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from '../../components/shared/Loading';

const { Option } = Select;
const EditUser = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const [user,setUser] =useState();
  
    const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);

  const onFinish = async (values) => {
    let payload = {
      firstName: values.firstName,
      lastName:values.lastName,
      offerupNick:values.offerupNick ,
      password: values.password,
      role:values.role ,
      commissionRate: values.commissionRate,
      billingInfo: {
        fullName: values.fullName,
        email: values.email,
        address: values.address,
        iban: values.iban,
        swift: values.swift,
      },
    }
    try {
      await service.editUser(params.id,payload);
      toast.success("User Updated!");
    } catch (error) {
      toast.error("Error occurred!")
    }
    
  };
  const onFinishFailed = () => {
    toast.error("Error occurred!");
  };
  const getUser = async (id)=>{
    
      try {
          const res = await service.getUser(id);
          setUser(res.data.data)
      } catch (error) {
          toast.error("Error occurred")
      }
     
  }

  let content;
  if(user){
      content = ( <Form
        form={form}
        className={styles.form}
        name="basic"
        labelCol={{
          span: 8
        }}
        wrapperCol={{offset:2,
          span: 14,
        }}
        
        initialValues={user && {firstName: user?.firstName,
                lastName: user?.lastName,
                offerupNick:user?.offerupNick,
                email:user?.email,
                commissionRate:user?.commissionRate,
                email:user?.billingInfo?.email,
                address:user?.billingInfo?.address,
               iban:user?.billingInfo?.iban,
               swift:user?.billingInfo?.swift,
            fullName:user?.billingInfo.fullName,
        role:user?.role}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="First Name"
          name="firstName"
            
          rules={[
            {
              required: true,
              message: "Please enter first name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please enter last name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Offerup Username"
          name="offerupNick"
          rules={[
            {
              required: true,
              message: "Please enter offerup username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="User Role"
          name="role"
          rules={[
            {
              required: true,
              message: "Please select user role!",
            },
          ]}
        >
          <Select placeholder="Select user role">
            <Option value="sales">Sales</Option>
            <Option value="admin">Admin</Option>
          </Select>
        </Form.Item>

        <h3 style={{ textAlign: "center" }}>Invoice Information</h3>
        <Form.Item
          label="Invoice Full Name"
          name="fullName"
          rules={[
            {
              required: true,
              message: "Please enter offerup username!",
            },
          ]}
        >
          <Input placeholder="Enter first name and last name" />
        </Form.Item>
        <Form.Item
          label="Invoice Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please enter an address!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="IBAN"
          name="iban"
          rules={[
            {
              required: true,
              message: "Please enter IBAN!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="SWIFT CODE"
          name="swift"
          rules={[
            {
              required: true,
              message: "Please enter SWIFT CODE!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Invoice Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter invoice email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      )
  }else{
      content = (<Loading/>)
  }

  
useEffect(() => {
    getUser(params?.id)
    
},[params?.id])

  return (
    <div className={styles.wrapper}>
        
      <ToastContainer />
      <h1>User Update</h1>
        {content}
    </div>
  )
  }

export default EditUser