import React, { useState, useEffect, useRef } from "react";
import styles from "./AllInquiries.module.css";
import { Row, Table, Form, Input, Button } from "antd";
import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const columns = [
  {
    title: "Staff First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Staff Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Offerup Name",
    dataIndex: "offerupNick",
    key: "offerupNick",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "To",
    dataIndex: "to",
    key: "to",
  },
  {
    title: "Region",
    dataIndex: "region",
    key: "region",
  },
  {
    title: "Notes",
    key: "notes",
    dataIndex: "notes",
  },
  {
    title: "Date/Time",
    key: "createdAt",
    dataIndex: "createdAt",
  },
];

const AllInquiries = () => {
  const [inquiries, setInquiries] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);

  const onFinish = (values) => {
    setQuery(values.data);
  };

  const onFinishFailed = (values) => {
    console.log("Error occurred!");
  };

  const getInquiries = (pageNum) => {
    service.getAllInquiries(query, pageNum).then((res) => {
      let temp = res.data.data;
      let result = {};
      const arr = [];
      temp.map((item) => {
        result.createdAt = new Date(item.createdAt).toLocaleString();
        result.offerupNick = item.user.offerupNick;
        result.firstName = item.user.firstName;
        result.lastName = item.user.lastName;
        result.customerName = item.customerName;
        result.productName = item.productName;
        result.to = item.to;
        result.region = item.region;
        result.notes = item.notes;
        arr.push(result);
        result = {};
      });
      setInquiries(arr);
      setTotal(res.data.count); // Set the total count from the backend response
    });
  };

  useEffect(() => {
    getInquiries(page);
  }, [page, query]);

  return (
    <div className={styles.wrapper}>
      <h1>All Leads</h1>
      <Row>
        <Form
          className={styles.form}
          name="basic"
          layout={"inline"}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="" name="data">
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </Row>
      {inquiries ? (
        <Table
          columns={columns}
          dataSource={inquiries}
          style={{ width: "100%" }}
          pagination={{
            pageSize: 30,
            current: page,
            total: total,
            onChange: (pageNum) => setPage(pageNum),
            showSizeChanger: false,
          }}
        />
      ) : (
        <h1>No data yet</h1>
      )}
    </div>
  );
};

export default AllInquiries;
