import { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Checkbox } from "antd";
import styles from "./EditCallReq.module.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import API_URL from "../../config";
import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";
const { TextArea } = Input;

const EditCallReq = () => {
  const params = useParams();
  const [call, setCall] = useState({});
  const [message, setMessage] = useState([]);
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const { user } = useContext(UserContext);
  const chatRef = useRef(null);
  let isAdmin = user?.role === "admin" ? true : false;

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [message]);
  const onFinish = (values) => {
    axios
      .put(`${API_URL}/api/v1/calls/${params.id}`, values, { withCredentials: true })
      .then((res) => {
        toast.success("Follow up updated!");
      })
      .catch((err) => toast.error("Error occurred!"));
  };
  const onFinishFailed = (errorInfo) => {
  };

  const handleSendMessage = async (e) => {
    if (e.message) {
      const messageData = {
        userId: user._id,
        message: e.message,
        callId: params.id,
      }
        try {
          await service.createThread(messageData)
          getCallById();
        }catch (e) {
          toast.error("Error occurred!")
        }
    }
  }

  const getCallById = async () => {

    try {

      const res = await service.getCallReqById(params.id);
      setCall(res.data.data)
      setMessage(res.data.data.thread)

    } catch (error) {
      toast.error("Error occurred!")
    }

  };
  useEffect(() => {
    getCallById();
  }, [params.id]);

  return (
      <div className={styles.row}>
        <div className={styles.wrapper}>
          <ToastContainer/>
          <h1>Edit</h1>
          {call._id ? (
              <Form
                  className={styles.form}
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{...call}}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
              >
                <Form.Item
                    label="Customer Name"
                    name="customerName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter customer name!",
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                    label="Customer Phone"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter customer phone!",
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                    label="Notes"
                    name="salesmanNote"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                >
                  <TextArea/>
                </Form.Item>
                <Form.Item
                    name="status"
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                >
                  <Checkbox.Group
                      disabled={!isAdmin}
                      style={{display: "flex", flexDirection: "column"}}
                  >
                    <Checkbox value="noAnswer" style={{margin: 0}}>No answer</Checkbox>
                    <Checkbox value="callAgain" style={{margin: 0}}>Needs to call again</Checkbox>
                    <Checkbox value="comeStore" style={{margin: 0}}>Will come store</Checkbox>
                    <Checkbox value="purchaseWebsite" style={{margin: 0}}>Will purchase from website</Checkbox>
                    <Checkbox value="financingLinks" style={{margin: 0}}>Financing links sent</Checkbox>
                    <Checkbox value="invoiceSent" style={{margin: 0}}>Invoice sent</Checkbox>
                    <Checkbox value="paidSold" style={{margin: 0}}>Paid and sold</Checkbox>
                    <Checkbox value="purchasedAnotherStore" style={{margin: 0}}>Purchased from another store</Checkbox>
                    <Checkbox value="notInterested" style={{margin: 0}}>Not interested anymore</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item
                    label="Staff Chat"
                    name="callerNote"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                >
                  <TextArea disabled={!isAdmin}/>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                      offset: 4,
                      span: 16,
                    }}
                >
                  <Button type="primary" htmlType="submit" className={styles.btn}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
          ) : (
              <h1>Loading....</h1>
          )}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.chat}>
            <div className={styles.chatHeader}>
              <h1>Staff Chat</h1>
            </div>
            <div className={styles.chatBody}
                 ref={chatRef}
            >
              {
                message.map((item, index) => {
                  return (
                      <div className={styles.box} key={index}>
                        {
                          // image, name, time, message
                        }
                        <div className={styles.message}>
                          <div className={styles.image}>
                            {`${item.userId?.firstName.charAt(0)}${item.userId?.lastName.charAt(0)}`}
                          </div>
                          <div className={styles.messageBody}>
                            <div className={styles.messageHeader}>
                              <h3>{`${item.userId?.firstName} ${item.userId?.lastName}`}</h3>
                              <p>{new Date(item.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                    }

                              )}</p>
                            </div>
                            <div className={styles.messageContent}>
                              <p>
                                {item.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                  )
                })
              }

            </div>
            <div className={styles.chatFooter}>
              <div className={styles.chatBox}>

                <Form
                    onFinish={handleSendMessage}
                    onFinishFailed={onFinishFailed}
                    onSubmitCapture={(e) => e.preventDefault()}
                    className={styles.formMessage}
                >
                  <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message!",
                          className: styles.formAlert,
                        },
                      ]}
                      className={styles.formInput}
                  >
                    <TextArea
                        placeholder="Type a message"
                        id="message"
                        autoSize={{minRows: 3, maxRows: 5}}
                    />
                  </Form.Item>
                  <Form.Item className={styles.formBottom}>
                    <Button type="primary" htmlType="submit">
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default EditCallReq;
