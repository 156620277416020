// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SalesNotice_wrapper__47aim {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    height: 100vh;\n    padding: 20px 40px;\n  }\n  .SalesNotice_wrapper__47aim h1 {\n    margin-top: 2rem;\n    font-size: 2rem;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/pages/salesNotice/SalesNotice.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    height: 100vh;\n    padding: 20px 40px;\n  }\n  .wrapper h1 {\n    margin-top: 2rem;\n    font-size: 2rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SalesNotice_wrapper__47aim"
};
export default ___CSS_LOADER_EXPORT___;
