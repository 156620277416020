import React, { useEffect, useState, useRef } from "react";
import styles from "./AllUsers.module.css";
import { Link } from "react-router-dom";
import { Row, Table, Form, Input, Button } from "antd";
import alertNotification from "../../utils/alertNotification";
import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Offerup Username",
    dataIndex: "offerupNick",
    key: "offerupNick",
  },
  {
    title: "Date/Time",
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: "Edit",
    key: "edit",
    render: (_, record) => (
      <Link to={`/user-details/${record._id}`}>Details</Link>
    ),
  },
];

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);

  const onFinish = (values) => {
    setQuery(values.data);
  };

  const onFinishFailed = () => {
    alertNotification("Error occurred!");
  };

  const getAllUsers = (query, pageNum) => {
    service.getAllUsers(query, pageNum).then((res) => {
      setUsers(res.data.data);
      setTotal(res.data.count); // Set the total count from the backend response
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getAllUsers(query, page);
  }, [page, query]);

  return (
    <div className={styles.wrapper}>
      <h1>All Users</h1>
      <Row style={{ marginBottom: 20 }}>
        <Form
          className={styles.form}
          name="basic"
          layout={"inline"}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="" name="data">
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </Row>
      {users.length > 0 && (
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={users}
          pagination={{
            pageSize: 30,
            current: page,
            total: total,
            onChange: (pageNum) => setPage(pageNum),
            showSizeChanger: false,
          }}
        />
      )}
    </div>
  );
};

export default AllUsers;
