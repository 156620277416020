// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateUser_wrapper__3Gc8a {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  /* height: 100vh; */\n  padding: 40px 40px;\n}\n.CreateUser_form__QhfuP {\n  width: 100%;\n  \n}\n", "",{"version":3,"sources":["webpack://./src/pages/createUser/CreateUser.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;;AAEb","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  /* height: 100vh; */\n  padding: 40px 40px;\n}\n.form {\n  width: 100%;\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CreateUser_wrapper__3Gc8a",
	"form": "CreateUser_form__QhfuP"
};
export default ___CSS_LOADER_EXPORT___;
