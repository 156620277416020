// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditUser_wrapper__yuTZL {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    /* height: 100vh; */\n    padding: 40px 40px;\n  }\n  .EditUser_form__w2bL4 {\n    width: 100%;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/pages/editUser/EditUser.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,WAAW;EACb","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    /* height: 100vh; */\n    padding: 40px 40px;\n  }\n  .form {\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "EditUser_wrapper__yuTZL",
	"form": "EditUser_form__w2bL4"
};
export default ___CSS_LOADER_EXPORT___;
