import React, { useContext, useEffect, useState,useRef } from "react";
import styles from "./MySales.module.css";
import { Table } from "antd";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import API_URL from "../../config";
import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";
const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Offerup Username",
    dataIndex: "offerupNick",
    key: "offerupNick",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "Joint Sales",
    dataIndex: "isShared",
    key: "isShared",
  },
  {
    title: "Date/Time",
    dataIndex: "date",
    key: "date",
  },
];
const MySales = () => {
  const { user } = useContext(UserContext);
  const [page,setPage] = useState(1);
  const [sales, setSales] = useState([]);
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const getAllSales = () => {
    axios
      .get(`${API_URL}/api/v1/sales/${user?.id}`,{withCredentials:true})
      .then((res) => {
        let temp = res.data.data;
        temp = temp.map((item) => {
          item.date = new Date(item.date).toLocaleString();
          item.isShared === true
            ? (item.isShared = "Yes")
            : (item.isShared = "No");
          return item;
        });
        setSales(temp);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllSales();
  }, [page]);

  return (
    <div className={styles.wrapper}>
      <h1>My Sales</h1>
      {sales?.length > 0 && <Table columns={columns} dataSource={sales} pagination={{pageSize:30,onChange: (prev) => setPage(prev) }} />}
    </div>
  );
};

export default MySales;
