import { Button, Form, Input, Select } from "antd";

import React,{useRef} from "react";
import styles from "./CreateUser.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const { Option } = Select;
const CreateUser = () => {
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const [form] = Form.useForm()
  const onFinish = async (values) => {
    let payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      offerupNick:values.offerupNick,
      commissionRate:values.commissionRate*1,
      role:values.role,
      password:values.password,
      billingInfo:{
        billingEmail:values.email,
        fullName:values.fullName,
        address:values.address,
        iban:values.iban,
        swift:values.swift,
      }
      
      
    }
    try {
      await service.creatUser(payload);
      form.resetFields()
      toast.success("User created!");
    } catch (error) {
      toast.error("Error occurred!")
    }
    // axios
    //   .post(`${API_URL}/api/v1/auth/register`, values,{withCredentials:true})
    //   .then((res) => toast.success("Kullanıcı Oluşturuldu!"))
    //   .catch((err) => toast.error("Hata oluştu!"));
  };
  const onFinishFailed = () => {
    toast.error("Error occurred!");
  };
  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <h1>Create a User</h1>
      <Form
      form={form}
        className={styles.form}
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 12,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please enter first name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please enter last name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Offerup Username"
          name="offerupNick"
          rules={[
            {
              required: true,
              message: "Please enter offerup username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Commission Rate"
          name="commissionRate"
          rules={[
            {
              required: true,
              message: "Please enter commission rate!",
            },
          ]}
        >
          <Input placeholder="Please only enter a number such as '6' or '7'"/>
        </Form.Item>

        <Form.Item
          label="User Role"
          name="role"
          rules={[
            {
              required: true,
              message: "Please select user role!",
            },
          ]}
        >
          <Select placeholder="Select User Role">
            <Option value="sales">Sales</Option>
            <Option value="admin">Admin</Option>
          </Select>
        </Form.Item>
        <h3 style={{ textAlign: "center" }}>Invoice Information</h3>
        <Form.Item
          label="Invoice Full Name"
          name="fullName"
          rules={[
            {
              required: true,
              message: "Please enter offerup username!",
            },
          ]}
        >
          <Input placeholder="Enter first name and last name" />
        </Form.Item>
        <Form.Item
          label="Invoice Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please enter an adress!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="IBAN"
          name="iban"
          rules={[
            {
              required: true,
              message: "Please enter IBAN!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="SWIFT CODE"
          name="swift"
          rules={[
            {
              required: true,
              message: "Please enter SWIFT CODE!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Invoice Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter invoice email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 12,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateUser;
