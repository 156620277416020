// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.UserDetails_wrapper__MOqVE {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.UserDetails_card__1UMmD {\n  width: 60%;\n  margin: auto;\n}\n.UserDetails_wrapper__MOqVE h1 {\n  font-size: 2rem;\n}\n\n.UserDetails_wrapper__MOqVE th {\n  text-align: start;\n}", "",{"version":3,"sources":["webpack://./src/pages/userDetails/UserDetails.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["\n.wrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.card {\n  width: 60%;\n  margin: auto;\n}\n.wrapper h1 {\n  font-size: 2rem;\n}\n\n.wrapper th {\n  text-align: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UserDetails_wrapper__MOqVE",
	"card": "UserDetails_card__1UMmD"
};
export default ___CSS_LOADER_EXPORT___;
