import axios from "axios";

export default function createChannel() {
  const controller = new AbortController();
  const token = document.cookie.slice(" ")[1];
  const request = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
    signal: controller.signal,
  });
  return { request, controller };
}