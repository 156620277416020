import React from "react";
import { Link } from "react-router-dom";
import { Image } from "antd";
import styles from "./SideBar.module.css";
import useAuth from "../hooks/useAuth";



const AdminSideBar = () => {
  const { logoutUser } = useAuth();
  return (
    <div
      className={styles.admin}
    >
      <Image
        className={styles.logo}
        preview={false}
        src="https://cdn.shopify.com/s/files/1/0572/8534/5467/files/ornate_banner_1-1_1_195x@2x.png?v=1655840930"
      />
      <Link
        className={styles.link}
        to="/dashboard"
      >
        Dashboard
      </Link>
      <Link
        className={styles.link}
        to="/all-inquiries"
      >
        All Leads
      </Link>

      <Link
       className={styles.link}
        to="/create-user"
      >
        Create a User
      </Link>

      <Link
       className={styles.link}
        to="/all-users"
      >
        All Users
      </Link>

      <Link
        className={styles.link}
        to="/all-call-reqs"
      >
        Follow Ups
      </Link>

      <Link
        className={styles.link}
        to="/create-sale"
      >
        Create a Commission
      </Link>

      <Link
        className={styles.link}
        to="/all-sales"
      >
        All Sales
      </Link>
      <Link
        className={styles.link}
        to="/create-invoice"
      >
        Create an Invoice
      </Link>
      <button onClick={logoutUser} className={styles.btn}>Logout</button>
    </div>
  );
};

export default AdminSideBar;
