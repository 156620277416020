import { createContext, useEffect, useRef, useState } from "react";
import authService from "../services/authService";
import createHttpClient from "../utils/createHttpClient";
export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  // const { user, setUser, isLoading } = useCheckAuth();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { request } = useRef(createHttpClient()).current;
  const service = new authService(request);
  const checkAuthStatus = (from) => {
    setIsLoading(true);
    service
      .checkUserAuth()
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        setUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    checkAuthStatus();
  }, []);
  return (
    <UserContext.Provider value={{ user, setUser, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};
