// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dashboard_wrapper__2b6VF {\n  width: 100%;\n  \n  padding: 0px 40px;\n}\n.Dashboard_wrapper__2b6VF h1 {\n  font-size: 2rem;\n}\n.Dashboard_card__KwjEW {\n  height: 20vh;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;EACE,YAAY;AACd","sourcesContent":[".wrapper {\n  width: 100%;\n  \n  padding: 0px 40px;\n}\n.wrapper h1 {\n  font-size: 2rem;\n}\n.card {\n  height: 20vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Dashboard_wrapper__2b6VF",
	"card": "Dashboard_card__KwjEW"
};
export default ___CSS_LOADER_EXPORT___;
