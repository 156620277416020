import React, { useContext ,useRef} from "react";
import { Button, Form, Input, Select } from "antd";
import styles from "./CreateInquiry.module.css";

import { UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const { Option } = Select;
const { TextArea } = Input;

const CreateInquiry = () => {
  const { user } = useContext(UserContext);
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const [form]  = Form.useForm()
  const onFinish = async (values) => {
    let payload = {user:{offerupNick:user.offerupNick,
      firstName: user.firstName, lastName: user.lastName,
      userId:user.userId
    
    }
  ,...values}
    try {
      await service.createInquiry(payload);
      form.resetFields();
      toast.success("Lead created!");
    } catch (error) {
      toast.error("Error occurred!");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <h1>Create a Lead</h1>
      <Form
      form={form}
        className={styles.form}
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Customer Name"
          name="customerName"
          rules={[
            {
              required: true,
              message: "Please enter customer name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product Name"
          name="productName"
          rules={[
            {
              required: true,
              message: "Please enter product name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="To"
          name="to"
          rules={[
            {
              required: true,
              message: "Please select an option!",
            },
          ]}
        >
          <Select placeholder="Select an Option">
            <Option value="web">Web</Option>
            <Option value="showroom">Showroom</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Region"
          name="region"
          rules={[
            {
              required: true,
              message: "Please select a region!",
            },
          ]}
        >
          <Select placeholder="Select a Region">
            <Option value="santa ana">Santa Ana</Option>
            <Option value="san bernardino">San Bernardino</Option>
            <Option value="san diego">San Diego</Option>
            <Option value="los angeles">Los Angeles</Option>
            <Option value="outer areas">Outer Areas</Option>
            <Option value="facebook">Facebook</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Notes"
          name="notes"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" className={styles.btn}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateInquiry;
