import React from 'react'
import styles from "./Pagination.module.css"
import { LeftOutlined,RightOutlined } from '@ant-design/icons'
const Pagination = ({page,setPage}) => {

    const handlePageDown = ()=>{
        setPage(prev=>prev-1)
    }
    const handlePageUp = ()=>{
        setPage(prev=>prev+1)
    }
  return (
    <div className={styles.wrapper}>

        <LeftOutlined onClick={handlePageDown} className={styles.icons}/>
        <h4 className={styles.page}>{page}</h4>
        <RightOutlined onClick={handlePageUp} className={styles.icons}/>
    </div>
  )
}

export default Pagination