import "./assets/style/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// routers
import PrivateRoute from "./routers/PrivateRoute";
import PublicRoute from "./routers/PublicRoute";
import SalesRoute from "./routers/SalesRoute";
import SharedRoute from "./routers/SharedRoute";

// pages
import SignIn from "./pages/signIn";
import CreateInquiry from "./pages/createInquiry/CreateInquiry";
import CreateInvoice from "./pages/createInvoice/CreateInvoice";
import MyInquiries from "./pages/myInquiries/MyInquiries";
import EditInquiry from "./pages/editInquiry/EditInquiry";
import AllInquiries from "./pages/allInquiries/AllInquiries";
import CreateUser from "./pages/createUser/CreateUser";
import AllUsers from "./pages/allUsers/AllUsers";
import CreateCallReq from "./pages/createCall/CreateCallReq";
import MyCallReqs from "./pages/myCallReqs/MyCallReqs";
import EditCallReq from "./pages/editCall/EditCallReq";
import AllCallReqs from "./pages/allCallReqs/AllCallReqs";
import CreateSale from "./pages/createSale/CreateSale";
import AllSales from "./pages/allSales/AllSales";
import EditSale from "./pages/editSale/EditSale";
import MySales from "./pages/mySales/MySales";
import Dashboard from "./pages/dashboard/Dashboard";
import SalesNotice from "./pages/salesNotice/SalesNotice";
import { UserProvider } from "./context/UserContext";
import EditUser from "./pages/editUser/EditUser";
import UserDetails from "./pages/userDetails/UserDetails";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<SignIn />} />
          </Route>

          <Route path="/" element={<SalesRoute />}>
            <Route path="/create-inquiry" element={<CreateInquiry />} />
            <Route path="/my-inquiries" element={<MyInquiries />} />
            <Route path="/edit-inquiry/:id" element={<EditInquiry />} />
            <Route path="/create-call-req" element={<CreateCallReq />} />
            <Route path="/my-call-reqs" element={<MyCallReqs />} />
            <Route path="/my-sales" element={<MySales />} />
            <Route path="/sales-notice" element={<SalesNotice />} />

          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/all-inquiries" element={<AllInquiries />} />
            <Route path="/create-invoice" element={<CreateInvoice />} />
            <Route path="/create-user" element={<CreateUser />} />
            <Route path="/all-users" element={<AllUsers />} />
            <Route path="/all-call-reqs" element={<AllCallReqs />} />
            <Route path="/create-sale" element={<CreateSale />} />
            <Route path="/edit-sale/:id" element={<EditSale />} />
            <Route path="/edit-user/:id" element={<EditUser />} />
            <Route path="/user-details/:id" element={<UserDetails />} />
          </Route>
          <Route path="/" element={<SharedRoute />}>
            <Route path="/edit-call-req/:id" element={<EditCallReq />} />
            <Route path="/all-sales" element={<AllSales />} />
          </Route>
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
