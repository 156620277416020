// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideDrawer_btn__PsTqk{\n    background-color: #e00838;\n    border: none;\n    width: 30%;\n    font-size: 1rem;\n    position:fixed;\n    top: 0;\n    left: 0;\n}\n.SideDrawer_link__dqQmn {\n    display: block;\n    font-size: 1.1rem;\n    margin-bottom: 30px;\n    font-weight: bold;\n    color: #e02938 ;\n    padding: 5px 10px;\n    width: 100%;\n  }\n  \n  .SideDrawer_link__dqQmn :hover {\n    color: white!important;\n  }\n  \n  .SideDrawer_logOutBtn__9Aicy {\n    background-color: #e00838;\n    color: aliceblue;\n    border: none;\n    width: 100%;\n    font-size: 1rem;\n    padding: 5px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    &:hover {\n      background-color: #c0062d;\n    }\n  }", "",{"version":3,"sources":["webpack://./src/layouts/drawer/SideDrawer.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,UAAU;IACV,eAAe;IACf,cAAc;IACd,MAAM;IACN,OAAO;AACX;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".btn{\n    background-color: #e00838;\n    border: none;\n    width: 30%;\n    font-size: 1rem;\n    position:fixed;\n    top: 0;\n    left: 0;\n}\n.link {\n    display: block;\n    font-size: 1.1rem;\n    margin-bottom: 30px;\n    font-weight: bold;\n    color: #e02938 ;\n    padding: 5px 10px;\n    width: 100%;\n  }\n  \n  .link :hover {\n    color: white!important;\n  }\n  \n  .logOutBtn {\n    background-color: #e00838;\n    color: aliceblue;\n    border: none;\n    width: 100%;\n    font-size: 1rem;\n    padding: 5px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    &:hover {\n      background-color: #c0062d;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "SideDrawer_btn__PsTqk",
	"link": "SideDrawer_link__dqQmn",
	"logOutBtn": "SideDrawer_logOutBtn__9Aicy"
};
export default ___CSS_LOADER_EXPORT___;
