// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AllInquiries_wrapper__5\\+Qu8 {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n  /* padding-left: 20%; */\n}\n.AllInquiries_wrapper__5\\+Qu8 h1 {\n  font-size: 2rem;\n}\n.AllInquiries_formWrapper__4qI1T {\n  margin-bottom: 30px;\n  width: 100%;\n}\n.AllInquiries_form__S6baM {\n  width: 100%;\n  margin-bottom: 30px;\n}", "",{"version":3,"sources":["webpack://./src/pages/allInquiries/AllInquiries.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n  /* padding-left: 20%; */\n}\n.wrapper h1 {\n  font-size: 2rem;\n}\n.formWrapper {\n  margin-bottom: 30px;\n  width: 100%;\n}\n.form {\n  width: 100%;\n  margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AllInquiries_wrapper__5+Qu8",
	"formWrapper": "AllInquiries_formWrapper__4qI1T",
	"form": "AllInquiries_form__S6baM"
};
export default ___CSS_LOADER_EXPORT___;
