import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import axios from "axios";
import styles from "./Dashboard.module.css";
import API_URL from "../../config";

const Dashboard = () => {
  const [revenue, setRevenue] = useState(0);
  const [inquiry, setInquiry] = useState(0);
  const [callReq, setCallReq] = useState(0);

  const getRevenue = () => {
    axios
      .get(`${API_URL}/api/v1/sales/total`)
      .then((res) => setRevenue(res.data.data))
      .catch((err) => console.log(err));
  };
  const getInquiries = () => {
    axios
      .get(`${API_URL}/api/v1/inquiries`)
      .then((res) => setInquiry(res.data.results))
      .catch((err) => console.log(err));
  };
  const getCallReqs = () => {
    axios
      .get(`${API_URL}/api/v1/calls`)
      .then((res) => setCallReq(res.data.results))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getRevenue();
    getInquiries();
    getCallReqs();
  }, []);
  return (
    <Row justify="middle" align="top" className={styles.wrapper}>
      <Col span={12} className={styles.card}>
        <Card>
          <h1>Total Revenue</h1>
          <h1>{revenue}</h1>
        </Card>
      </Col>
      <Col span={12} className={styles.card}>
        <Card>
          <h1>Total Leads</h1>
          <h1>{inquiry}</h1>
        </Card>
      </Col>
      <Col span={12} className={styles.card}>
        <Card>
          <h1>Total Follow Ups</h1>
          <h1>{callReq}</h1>
        </Card>
      </Col>
    </Row>
  );
};

export default Dashboard;
