import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Form, Input, Checkbox, Row, Col, Image } from "antd";
import styles from "./SignIn.module.css";
import useAuth from "../../hooks/useAuth";

export default function SignIn(props) {
  const { loginUser } = useAuth();
  let location = useLocation();
  let redirectPage = location.state && location.state.from.pathname;

  const onFinish = async (values) => {
    loginUser(values, redirectPage);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row className={styles.loginWrapper}>
      <Col span={24} className={styles.loginFormWrapper}>
        <Image
          preview={false}
          src="https://cdn.shopify.com/s/files/1/0572/8534/5467/files/ornate_banner_1-1_1_195x@2x.png?v=1655840930"
        />
        <Form
          className={styles.loginForm}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="offerupNick"
            rules={[
              { required: true, message: "Please enter your offerup username!" },
            ]}
          >
            <Input
              placeholder="Offerup Username"
              className={styles.formInput}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password placeholder="Password" className={styles.formInput} />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <button type="primary" className={styles.loginFormButton}>
            LOGIN
          </button>
        </Form>
      </Col>
    </Row>
  );
}
