import React, { useEffect, useState,useRef } from "react";
import { Button, DatePicker, Form, Input, Select, Checkbox } from "antd";
import styles from "./CreateSale.module.css";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const { Option } = Select;
const CreateSale = () => {
  const [users, setUsers] = useState([]);
  const [query,setQuery] = useState("");
  const [page,setPage] = useState(1);

  const [form]  = Form.useForm()
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const handleChange = (e) => {
    console.log(users);
  };
  const onFinish = async (values) => {
    try {
      await service.createSales(values);
     form.resetFields();
      toast.success("Sale created!");
    } catch (error) {
      toast.error("Error occurred!");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = async (query,page) => {
    try {

      const res = await service.getAllUsers(query,page);
      setUsers(res.data.data);
    }    
 catch (error) {
    toast.error("Error occurred!");
  }};
  useEffect(() => {
    getUsers(query,page);
  }, [query,page]);
  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <h1>Create a Commission</h1>
      <Form
      form={form}
        className={styles.form}
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Sales Staff"
          name="userId"
          rules={[
            {
              required: true,
              message: "Please select sales staff!",
            },
          ]}
        >
          <Select placeholder="Select Sales Staff" onChange={handleChange}>
            {users?.map((item) => {
              return (
                <Option key={item._id} value={item._id}>
                  {item.firstName} {item.lastName}{" "}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Customer Name"
          name="customerName"
          rules={[
            {
              required: true,
              message: "Please enter customer name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product Name"
          name="productName"
          rules={[
            {
              required: true,
              message: "Please enter product name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="isShared"
          valuePropName="checked"
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Checkbox>Joint Sales</Checkbox>
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" className={styles.btn}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateSale;
