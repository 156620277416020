import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import styles from "./MyInquiries.module.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import API_URL from "../../config";
import Pagination from "../../components/shared/Pagination";

const MyInquiries = () => {
  const [page,setPage]=useState(1);
  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "Notes",
      key: "notes",
      dataIndex: "notes",
    },
    {
      title: "Date/Time",
      key: "createdAt",
      dataIndex: "createdAt",
    },
   
  ];
  const [inquiries, setInquiries] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const getInquiries = () => {
      axios.get(`${API_URL}/api/v1/inquiries/${user?.id}?page=${page}`,{withCredentials:true}).then((res) => {
        let temp = res.data.data;
        console.log(res.data.data.length)
        temp = temp.map((item) => {
          item.createdAt = new Date(item.createdAt).toLocaleString();

          return { ...item, key: item._id };
        });
        setInquiries(temp);
      });
    };
    getInquiries();
  }, [page]);
  return (
    <div className={styles.wrapper}>
      <h1>My Leads</h1>
      {inquiries ? (
        <>
        <Table
          columns={columns}
          dataSource={inquiries}
          pagination={false}
          style={{ width: "100%" }}
          
        />
        <Pagination setPage={setPage} page={page}/>
        </>
      ) : (
        <h1>No data yet</h1>
      )}
    </div>
  );
};

export default MyInquiries;
