// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateCallReq_wrapper__6AXHm {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.CreateCallReq_form__5dgYD {\n  width: 100%;\n}\n.CreateCallReq_btn__S8Wpo {\n  background-color: #e00838;\n  border: none;\n  width: 30%;\n  font-size: 1rem;\n}\n\n@media screen and (max-width:900px) {\n  .CreateCallReq_wrapper__6AXHm{\n    padding-left: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/createCall/CreateCallReq.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.form {\n  width: 100%;\n}\n.btn {\n  background-color: #e00838;\n  border: none;\n  width: 30%;\n  font-size: 1rem;\n}\n\n@media screen and (max-width:900px) {\n  .wrapper{\n    padding-left: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CreateCallReq_wrapper__6AXHm",
	"form": "CreateCallReq_form__5dgYD",
	"btn": "CreateCallReq_btn__S8Wpo"
};
export default ___CSS_LOADER_EXPORT___;
