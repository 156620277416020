// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateCall_wrapper__-covh {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.CreateCall_form__wzFHo {\n  width: 100%;\n  margin-bottom: 20px;\n}\n.CreateCall_formWrapper__E2oy6 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n.CreateCall_btn__iRfGI {\n  background-color: #e00838 !important;\n  color: aliceblue !important;\n  border: none;\n  width: 100%;\n  font-size: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/createInvoice/CreateCall.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,oCAAoC;EACpC,2BAA2B;EAC3B,YAAY;EACZ,WAAW;EACX,eAAe;AACjB","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.form {\n  width: 100%;\n  margin-bottom: 20px;\n}\n.formWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n.btn {\n  background-color: #e00838 !important;\n  color: aliceblue !important;\n  border: none;\n  width: 100%;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CreateCall_wrapper__-covh",
	"form": "CreateCall_form__wzFHo",
	"formWrapper": "CreateCall_formWrapper__E2oy6",
	"btn": "CreateCall_btn__iRfGI"
};
export default ___CSS_LOADER_EXPORT___;
