import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import SideDrawer from "./drawer/SideDrawer";
import SideBar from "./SideBar";

export default function DashboardLayout({ children }) {
  const {width} = useWindowDimensions();
  return (
    <div style={{ display: "flex", width: "100%" }}>
      {
        width<= 900? <SideDrawer/>:
      <SideBar />
      }
      {children}
    </div>
  );
}
