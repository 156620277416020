import { Button, Drawer,Image } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import styles from "./SideDrawer.module.css"
const SideDrawer = () => {
  const { logoutUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('left');
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer} className={styles.btn}>
          Menu
        </Button>
      
      <Drawer
        title={<Image
          preview={false}
          width={200}
          
          src="https://cdn.shopify.com/s/files/1/0572/8534/5467/files/ornate_banner_1-1_1_195x@2x.png?v=1655840930"
        />}
        width="250px"
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
         

         <Link
        className={styles.link}
        to="/dashboard"
      >
        Dashboard
      </Link>
      <Link
        className={styles.link}
        to="/all-inquiries"
      >
        All Leads
      </Link>

      <Link
       className={styles.link}
        to="/create-user"
      >
        Create a User
      </Link>

      <Link
       className={styles.link}
        to="/all-users"
      >
        All Users
      </Link>

      <Link
        className={styles.link}
        to="/all-call-reqs"
      >
        Follow Ups
      </Link>

      <Link
        className={styles.link}
        to="/create-sale"
      >
        Create a Commission
      </Link>

      <Link
        className={styles.link}
        to="/all-sales"
      >
        All Sales
      </Link>
      <Link
        className={styles.link}
        to="/create-invoice"
      >
        Create an Invoice
      </Link>
      <button onClick={logoutUser} className={styles.logOutBtn}>Logout</button>
      </Drawer>
    </>
  );
};
export default SideDrawer;