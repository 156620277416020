import React, { useState,useRef, useEffect } from "react";
import { Button, Form, Input, DatePicker, Table } from "antd";
import styles from "./CreateCall.module.css";
import axios from "axios";
import API_URL from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { saveAs } from "file-saver";
import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const { TextArea } = Input;

const CreateInvoice = () => {
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const [query,setQuery]= useState("");
  const [data, setData] = useState([]);
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
    },
    {
      title: "Offerup Name",
      dataIndex: "offerupNick",
      key: "offerupNick",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Process",
      key: "createInvoice",
      render: (_, record) => (
        <Button
          className={styles.btn}
          onClick={() =>
            handleClick(
              record.offerupNick,
              record.firstName,
              record.lastName,
              record.commission,
              record.address
              
            )
          }
        >
          Create
        </Button>
      ),
    },
  ];
  //GET SELLERS MONTHLY COMMISSIONS
  const onFinish = async (values) => {
    values.dateGte = new Date(values.dateGte).toISOString();

    values.dateLt = new Date(values.dateLt).toISOString();

    try {
      const res = await service.getCommissions(values);
      setData(res.data.data);

    } catch (error) {
      toast.error("Error occurred!")
    }

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClick = (offerupNick, firstName, lastName, commission,address) => {
  
    axios
      .post(`${API_URL}/api/v1/invoice`, { offerupNick,firstName,lastName, commission,address},{withCredentials:true})
      .then(() => {
        toast.success("Invoice PDF oluşturuldu!");
        axios.get(`${API_URL}/api/v1/invoice`, { responseType: "blob" ,withCredentials:true}).then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });

          saveAs(pdfBlob, `${firstName}${lastName}.pdf`);
        });
      })
      .catch((err) => toast.error("Error occurred!"));
  };
  
  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <h1>Create an Invioce</h1>
      <Form
        className={styles.form}
        name="basic"
        layout="inline"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className={styles.formWrapper}>
          <Form.Item
            label="Starting Date"
            name="dateGte"
            rules={[
              {
                required: true,
                message: "Please select the date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="dateLt"
            rules={[
              {
                required: true,
                message: "Please select the date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.btn}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Table style={{ width: "100%" }} columns={columns} dataSource={data} />
    </div>
  );
};

export default CreateInvoice;
