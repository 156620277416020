import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

import AdminSideBar from "./AdminSideBar";
import AdminSideDrawer from "./drawer/AdminSideDrawer";

export default function AdminLayout({ children }) {
  const {width} = useWindowDimensions()
  return (
    <div style={{ display: "flex", width: "100vw",position:"relative"}}>
      
      {
        width<= 900? <AdminSideDrawer/>:
      <AdminSideBar />
      }
      <div style={{
        height: "100vh",
        ...(width > 900 && { overflowY: "scroll" }),
        width: "80vw"
      }}>
       {children}
      </div>
    </div>
  );
}
