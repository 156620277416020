import React, { useState, useEffect, useRef } from "react";
import { Row, Table, Form, Input, Button, Select, Option } from "antd";
import { Link } from "react-router-dom";
import styles from "./AllCallReqs.module.css";
import alertNotification from "../../utils/alertNotification";
import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const columns = [
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Staff Name",
    dataIndex: "salesmanName",
    render: (_, record) => (
      <>{`${record.user.firstName} ${record.user?.lastName}`}</>
    ),
  },
  {
    title: "Staff Note",
    dataIndex: "salesmanNote",
    key: "salesmanNote",
  },
  {
    title: "Call Status",
    key: "status",
    render: (_, record) => <>{
      record.status.map((item, index) => {
        switch (item) {
            case "noAnswer":
                return <p key={index}>No answer</p>
            case "callAgain":
                return <p key={index}>Needs to call again</p>
            case "comeStore":
                return <p key={index}>Will come store</p>
            case "purchaseWebsite":
                return <p key={index}>Will purchase from website</p>
            case "financingLinks":
                return <p key={index}>Financing links sent</p>
            case "invoiceSent":
                return <p key={index}>Invoice sent</p>
            case "paidSold":
                return <p key={index}>Paid and sold</p>
            case "purchasedAnotherStore":
                return <p key={index}>Purchased from another store</p>
            case "notInterested":
                return <p key={index}>Not interested anymore</p>
            default:
                return <p key={index}>{item}</p>
        }
      })
    }</>,
  },
  {
    title: "Date/Time",
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: "Edit",
    key: "edit",
    render: (_, record) => (
      <Link to={`/edit-call-req/${record._id}`}>Edit</Link>
    ),
  },
];

const AllCallReqs = () => {
  const [calls, setCalls] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({data: "", filter: []});
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const { Option } = Select;

  const onFinish = (values) => {
    setQuery({
      data: values.data,
      filter: values.filter
    });
  };

  const onFinishFailed = (values) => {
    alertNotification("Error occurred!");
  };

  const getCalls = (pageNum) => {
    service.getAllCallReqs(query?.data, pageNum, query.filter?.join(",")).then((res) => {
      let temp = res.data.data;
      temp = temp.map((item) => {
        item.createdAt = new Date(item.createdAt).toLocaleString();
        if (item.isCalled === true) {
          item.isCalled = "Called";
        } else {
          item.isCalled = "Pending";
        }

        return { ...item, key: item._id };
      });
      setCalls(temp);
      setTotal(res.data.count); // Set the total count from the backend response
    });
  };

  useEffect(() => {
    getCalls(page);
    console.log([page, query]);
  }, [page, query]);

  return (
    <div className={styles.wrapper}>
      <h1>Follow Ups</h1>
      <Row style={{ marginBottom: 20 }}>
        <Form
          className={styles.form}
          name="basic"
          layout={"inline"}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Filter" name="filter">
            <Select mode="multiple" style={{ width: 200 }} placeholder="Select a filter">
              <Option value="noAnswer">No answer</Option>
              <Option value="callAgain">Needs to call again</Option>
              <Option value="comeStore">Will come store</Option>
              <Option value="purchaseWebsite">Will purchase from website</Option>
              <Option value="financingLinks">Financing links sent</Option>
              <Option value="invoiceSent">Invoice sent</Option>
              <Option value="paidSold">Paid and sold</Option>
              <Option value="purchasedAnotherStore">Purchased from another store</Option>
              <Option value="notInterested">Not interested anymore</Option>
            </Select>
          </Form.Item>
          <Form.Item label="" name="data">
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </Row>
      
      {calls ? (
        <Table
          columns={columns}
          dataSource={calls}
          style={{ width: "100%" }}
          pagination={{
            pageSize: 30,
            current: page,
            total: total,
            onChange: (pageNum) => setPage(pageNum),
            showSizeChanger: false,
          }}
        />
      ) : (
        <h1>No data yet</h1>
      )}
    </div>
  );
};

export default AllCallReqs;
