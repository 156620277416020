import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import styles from "./MyCallReqs.module.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import API_URL from "../../config";

const columns = [
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },

  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Staff Note",
    dataIndex: "salesmanNote",
    key: "salesmanNote",
  },
  {
    title: "Call Status",
    key: "isCalled",
    dataIndex: "isCalled",
  },
  {
    title: "Yönetim Notu",
    dataIndex: "callerNote",
    key: "callerNote",
  },
  {
    title: "Date/Time",
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: "Edit",
    key: "edit",
    render: (_, record) => (
      <Link to={`/edit-call-req/${record._id}`}>Edit</Link>
    ),
  },
];
const MyCallReqs = () => {
  const [calls, setCalls] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const getCalls = () => {
      axios.get(`${API_URL}/api/v1/calls/my-calls`,{withCredentials:true}).then((res) => {
        let temp = res.data.data;
        temp = temp.map((item) => {
          item.createdAt = new Date(item.createdAt).toLocaleString();
          if (item.isCalled === true) {
            item.isCalled = "Called";
          } else {
            item.isCalled = "Pending";
          }

          return { ...item, key: item._id };
        });
        setCalls(temp);
      });
    };
    getCalls();
  }, [user.id]);
  return (
    <div className={styles.wrapper}>
      <h1>Follow Ups</h1>
      {calls ? (
        <Table columns={columns} dataSource={calls} style={{ width: "100%" }} />
      ) : (
        <h1>No data yet</h1>
      )}
    </div>
  );
};

export default MyCallReqs;
