import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Checkbox } from "antd";
import styles from "./EditSale.module.css";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import API_URL from "../../config";
import Loading from "../../components/shared/Loading";

const { Option } = Select;
const EditSale = () => {
  const params = useParams();
  const [users, setUsers] = useState([]);
  const [sale, setSale] = useState(null);
  const [form] = Form.useForm();

  const handleChange = (e) => {
    console.log(sale);
  };
  const onFinish = async (values) => {
    try {
      await axios.put(`${API_URL}/api/v1/sales/${params.id}`, values,{withCredentials:true});

      toast.success("Sale updated!");
    } catch (error) {
      toast.error("Error occurred!");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    axios
      .get(`${API_URL}/api/v1/users/all`,{withCredentials:true})
      .then((res) =>{ 
        
        setUsers(res.data.data)
        
      })
      .catch((err) => console.log(err));
  };
  const getSale = (id) => {
    axios
      .get(`${API_URL}/api/v1/sales/sale/${id}`,{withCredentials:true})
      .then((res) => {
        let result = res.data.data;
        let temp = {
          userId:result.user.userId,
          customerName:result.customerName,
          productName:result.productName,
          isShared:result.isShared,
          price:result.price,
         
        }
        setSale(temp);
        
      })
      .catch((err) => console.log(err));
  };
  
let content;
if(sale != null) {
  content = (
<Form
        form={form}
        className={styles.form}
        name="basic"
        labelCol={{
          span: 8
        }}
        wrapperCol={{offset:2,
          span: 14,
        }}
        initialValues={sale}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Sales Staff"
          name="userId"
          rules={[
            {
              required: true,
              message: "Please select sales staff!",
            },
          ]}
        >
          <Select placeholder="Select sales staff" onChange={handleChange}>
            {users?.map((item) => {
              return (
                <Option key={item._id} value={item._id}>
                  {item.firstName} {item.lastName}{" "}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Customer Name"
          name="customerName"
          rules={[
            {
              required: true,
              message: "Please enter customer name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product Name"
          name="productName"
          rules={[
            {
              required: true,
              message: "Please enter product name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="isShared"
          valuePropName="checked"
          wrapperCol={{
            offset: 10,
            span: 14,
          }}
        >
          <Checkbox>Joint Sales</Checkbox>
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        
        <Form.Item
          wrapperCol={{
            offset: 10,
            span: 14,
          }}
        >
          <Button type="primary" htmlType="submit" className={styles.btn}>
            Submit
          </Button>
        </Form.Item>
      </Form>
  )
}else{
  (<Loading/>)
}


  useEffect(() => {
    getUsers();
    getSale(params?.id);
  }, [params?.id]);

  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <h1>Sales Update</h1>
      {content}
    </div>
  );
};

export default EditSale;
