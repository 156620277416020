// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditInquiry_wrapper__WsA1J {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 50%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.EditInquiry_form__dedIz {\n  width: 80%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/editInquiry/EditInquiry.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,UAAU;EACV,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 50%;\n  height: 100vh;\n  padding: 20px 40px;\n}\n.form {\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "EditInquiry_wrapper__WsA1J",
	"form": "EditInquiry_form__dedIz"
};
export default ___CSS_LOADER_EXPORT___;
