import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import styles from "./EditInquiry.module.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import API_URL from "../../config";

const { Option } = Select;
const { TextArea } = Input;
const EditInquiry = () => {
  const params = useParams();
  const [inquiry, setInquiry] = useState({});

  const onFinish = (values) => {
    axios
      .put(`${API_URL}/api/v1/inquiries/${params.id}`, values)
      .then((res) => toast.success("Lead updated!"))
      .catch((err) => toast.error("Error occurred!"));
  };
  const onFinishFailed = (errorInfo) => {
  };

  useEffect(() => {
    const getInquiryById = () => {
      axios
        .get(`${API_URL}/api/v1/inquiries/inquiry/${params.id}`)
        .then((res) => {
          setInquiry(res.data.data);
        })
        .catch((err) => console.log(err));
    };
    getInquiryById();
  }, [params.id]);

  return (
    <div className={styles.wrapper}>
      <h1>Edit</h1>
      <ToastContainer />
      {inquiry._id ? (
        <Form
          className={styles.form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{ ...inquiry }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Customer Name"
            name="customerName"
            rules={[
              {
                required: true,
                message: "Please enter customer name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Product Name"
            name="productName"
            rules={[
              {
                required: true,
                message: "Please enter product name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="To"
            name="to"
            rules={[
              {
                required: true,
                message: "Please select an option!",
              },
            ]}
          >
            <Select placeholder="Select an Option">
              <Option value="web">Web</Option>
              <Option value="showroom">Showroom</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Notes"
            name="notes"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <h1>Loading....</h1>
      )}
    </div>
  );
};

export default EditInquiry;
