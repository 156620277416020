import React, { useEffect, useState,useRef } from "react";
import styles from "./AllSales.module.css";
import { Link } from "react-router-dom";
import { Table } from "antd";
import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Offerup Username",
    dataIndex: "offerupNick",
    key: "offerupNick",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "Joint Sales",
    dataIndex: "isShared",
    key: "isShared",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Commission",
    dataIndex: "commission",
    key: "commission",
  },
  {
    title: "Date/Time",
    dataIndex: "date",
    key: "date",
  },

  {
    title: "Edit",
    key: "edit",
    render: (_, record) => <Link to={`/edit-sale/${record._id}`}>Edit</Link>,
  },
];
const AllSales = () => {
  const [sales, setSales] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);

  const getAllSales = () => {
   service.getAllSales(query,page)
      .then((res) => {
        let temp = res.data.data;
        let result={};
        temp = temp.map((item) => {
          item.date = new Date(item.date).toLocaleString();

          item.isShared === true
            ? (item.isShared = "Yes")
            : (item.isShared = "No");
        result = {
          firstName:item.user.firstName,
          lastName:item.user.lastName,
          offerupNick:item.user.offerupNick,
          customerName:item.customerName,
          productName:item.productName,
          isShared:item.isShared,
          price:item.price,
          commission:item.commission,
          date:item.date,
          _id:item._id,
          
        }

          return result;
        });
        setSales(temp);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllSales();
  }, [page]);

  return (
    <div className={styles.wrapper}>
      <h1>All Sales</h1>
      {sales?.length > 0 && (
        <Table
          columns={columns}
          dataSource={sales}
          style={{ width: "100%" }}
          pagination={{ pageSize: 30, onChange: (prev) => setPage(prev) }}
        />
      )}
    </div>
  );
};

export default AllSales;
