import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import AdminLayout from "../layouts/AdminLayout";
import Loading from "../components/shared/Loading";

export default function PrivateRoute(props) {
  const { user, isLoading } = useContext(UserContext);

  let location = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (user?.role === "admin") {
    return (
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    );
  }
  return <Navigate to="/" state={{ from: location }} replace />;
}
