// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateInquiry_wrapper__1fi1L {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n}\n.CreateInquiry_form__h9\\+-H {\n  width: 100%;\n}\n.CreateInquiry_btn__CR48q {\n  background-color: #e00838;\n  border: none;\n  width: 30%;\n  font-size: 1rem;\n}\n\n@media screen and (max-width:900px) {\n  .CreateInquiry_wrapper__1fi1L{\n    padding-left: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/createInquiry/CreateInquiry.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n}\n.form {\n  width: 100%;\n}\n.btn {\n  background-color: #e00838;\n  border: none;\n  width: 30%;\n  font-size: 1rem;\n}\n\n@media screen and (max-width:900px) {\n  .wrapper{\n    padding-left: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CreateInquiry_wrapper__1fi1L",
	"form": "CreateInquiry_form__h9+-H",
	"btn": "CreateInquiry_btn__CR48q"
};
export default ___CSS_LOADER_EXPORT___;
