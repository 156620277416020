import { Card } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams,Link } from "react-router-dom";
import API_URL from "../../config";
import styles from "./UserDetails.module.css";

const UserDetails = () => {
  const params = useParams();

  const [user, setUser] = useState({});
  const getUser = (id) => {
    axios
      .get(`${API_URL}/api/v1/users/${id}`,{withCredentials:true})
      .then((res) => {setUser(res.data.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUser(params.id);
  }, [params.id]);

  return (
    <div className={styles.wrapper}>
      <Card className={styles.card}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <th>First Name:</th>
              <td>{user?.firstName}</td>
            </tr>
            <tr>
              <th>Last Name:</th>
              <td>{user?.lastName}</td>
            </tr>
            <tr>
              <th>Offerup Username</th>
              <td>{user?.offerupNick}</td>
            </tr>
            <tr>
              <th>Commission Rate</th>
              <td>{user?.commissionRate}</td>
            </tr>
            <tr>
              <th>Invoice Full Name</th>
              <td>{user?.billingInfo?.fullName}</td>
            </tr>
            <tr>
              <th>Invoice Address</th>
              <td>{user?.billingInfo?.address}</td>
            </tr>
            <tr>
              <th>IBAN</th>
              <td>{user?.billingInfo?.iban}</td>
            </tr>
            <tr>
              <th>SWIFT CODE</th>
              <td>{user?.billingInfo?.swift}</td>
            </tr>
            <tr>
              <th>Invoice Email</th>
              <td>{user?.billingInfo?.email}</td>
            </tr>
           
          </tbody>
        </table>
        <Link
        className={styles.link}
        to={`/edit-user/${params?.id}`}
      >
        Edit
      </Link>
      </Card>
    </div>
  );
};

export default UserDetails;