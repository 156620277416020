import React from "react";
import styles from "./SideBar.module.css";
import { Link } from "react-router-dom";
import { Image } from "antd";
import useAuth from "../hooks/useAuth";

export default function SideBar() {
  const { logoutUser } = useAuth();
  return (
    <div
      className={styles.admin}
    >
      <Image
        preview={false}
        src="https://cdn.shopify.com/s/files/1/0572/8534/5467/files/ornate_banner_1-1_1_195x@2x.png?v=1655840930"
      />

      <Link
       className={styles.link}
        to="/create-inquiry"
      >
        Create a Lead
      </Link>
      <Link
        className={styles.link}
        to="/my-inquiries"
      >
        My Leads
      </Link>
      <Link
        className={styles.link}
        to="/create-call-req"
      >
        Create a Follow Up
      </Link>
      <Link
        className={styles.link}
        to="/my-call-reqs"
      >
        My Follow Ups
      </Link>
      <Link
        className={styles.link}
        to="/my-sales"
      >
        My Sales
      </Link>
      <Link
        className={styles.link}
        to="/sales-notice"
      >
        Sales Notices
      </Link>
      <button onClick={logoutUser} className={styles.btn}>Logout</button>
    </div>
  );
}
