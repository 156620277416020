
import React, { useEffect, useState,useRef } from "react";
import { Table } from "antd";
import styles from "./SalesNotice.module.css";

import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";

const SalesNotice = () => {
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const [query,setQuery] = useState("");
  const [page,setPage] = useState(1);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Offerup Username",
      dataIndex: "offerupNick",
      key: "offerupNick",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Joint Sales",
      dataIndex: "isShared",
      key: "isShared",
    },
    {
      title: "Date/Time",
      dataIndex: "date",
      key: "date",
    },
  ];
  const [sales, setSales] = useState([]);

  const getSales =async (query,page) => {

    try {
      const res = await service.getSalesNotice(query,page);
      let temp = res.data.data;
      let result={};
      temp = temp.map((item) => {
        item.date = new Date(item.date).toLocaleString();
        item.isShared === true
          ? (item.isShared = "Yes")
          : (item.isShared = "No");

          result = {
            firstName:item.user.firstName,
            lastName:item.user.lastName,
            offerupNick:item.user.offerupNick,
            customerName:item.customerName,
            productName:item.productName,
            isShared:item.isShared,
            price:item.price,
            
            date:item.date
          }
  
            return result;
     
      });
      setSales(temp);
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getSales(query,page);
  }, [query,page]);
  return (
    <div className={styles.wrapper}>
      <h1>Sales Notices</h1>
      {sales.length > 0 && (
        <Table
          columns={columns}
          dataSource={sales}
          style={{width:"100%"}}
          pagination={{ pageSize: 30,onChange: (prev) => setPage(prev)  }}
        />
      )}
    </div>
  );
};

export default SalesNotice;