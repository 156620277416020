// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pagination_wrapper__XgNzM{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    width: 100%;\n}\n.Pagination_icons__SNOGu{\n    font-size: 1rem;\n    margin: 10px;\n    font-weight: bold;\n}\n.Pagination_page__-LuPA{\n    font-size: 1.2rem;\n    padding: 0;\n    margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/shared/Pagination.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,UAAU;IACV,SAAS;AACb","sourcesContent":[".wrapper{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    width: 100%;\n}\n.icons{\n    font-size: 1rem;\n    margin: 10px;\n    font-weight: bold;\n}\n.page{\n    font-size: 1.2rem;\n    padding: 0;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Pagination_wrapper__XgNzM",
	"icons": "Pagination_icons__SNOGu",
	"page": "Pagination_page__-LuPA"
};
export default ___CSS_LOADER_EXPORT___;
