import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import AdminLayout from "../layouts/AdminLayout";
import Loading from "../components/shared/Loading";
import DashboardLayout from "../layouts/DashboardLayout";

export default function SharedRoute(props) {
  const { user, isLoading } = useContext(UserContext);

  let location = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (user?.role === "admin" || user?.role === "sales") {
    return user?.role === "admin" ? (
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    ) : (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    );
  }
  return <Navigate to="/" state={{ from: location }} replace />;
}
