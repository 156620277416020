import { Button, Drawer,Image } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import styles from "./SideDrawer.module.css"
const SideDrawer = () => {
  const { logoutUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('left');
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  return (
    <>
      <Button type="primary" onClick={showDrawer} className={styles.btn}>
          Menu
        </Button>
      
      <Drawer
        title={<Image
          preview={false}
          width={200}
          
          src="https://cdn.shopify.com/s/files/1/0572/8534/5467/files/ornate_banner_1-1_1_195x@2x.png?v=1655840930"
        />}
        width="250px"
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
         

      <Link
       className={styles.link}
        to="/create-inquiry"
      >
        Create a Lead
      </Link>
      <Link
        className={styles.link}
        to="/my-inquiries"
      >
        My Leads
      </Link>
      <Link
        className={styles.link}
        to="/create-call-req"
      >
        Create a Follow Up
      </Link>
      <Link
        className={styles.link}
        to="/my-call-reqs"
      >
        My Follow Ups
      </Link>
      <Link
        className={styles.link}
        to="/my-sales"
      >
        My Sales
      </Link>
      <Link
        className={styles.link}
        to="/sales-notice"
      >
        Sales Notices
      </Link>
      <button onClick={logoutUser} className={styles.logOutBtn}>Logout</button>
      </Drawer>
    </>
  );
};
export default SideDrawer;