import React, { useContext,useRef } from "react";
import { Button, Form, Input } from "antd";
import styles from "./CreateCallReq.module.css";

import { UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../services/userService";
import createHttpClient from "../../utils/createHttpClient";


const { TextArea } = Input;

const CreateCallReq = () => {
  const { user } = useContext(UserContext);
  const { request } = useRef(createHttpClient()).current;
  const service = new UserService(request);
  const [form]  = Form.useForm()

  const onFinish = async (values) => {
    try {
      await service.createCallReq(values);
      form.resetFields();
      toast.success("Follow up created!");
    } catch (error) {
      toast.error("Error occurred!");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <h1>Create a Follow Up</h1>
      <Form
        form={form}
        className={styles.form}
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Customer Name"
          name="customerName"
          rules={[
            {
              required: true,
              message: "Please enter customer name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Customer Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter customer phone!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Note"
          name="salesmanNote"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" className={styles.btn}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateCallReq;
